import React from 'react';
import ReactDOM from "react-dom/client";
import { decode as decodeHtml } from 'html-entities';
import { stripHtml } from "string-strip-html";
import { OptionType, SelectPropsType, OptionsDataType, DataSetEntryType, AttrPropsDataType } from "./components/CustomizableSelect/types";
import { setObjectProperty, inlineStylesToObject, addEventListener, removeEventListener } from "./helpers";
import CustomizableSelect from "./components/CustomizableSelect";
import { default as htmlToReact } from 'html-react-parser';

class Initializer {
  selectors: string[] = [];

  bodyMutationObserver?: MutationObserver;

  isWatching: boolean = false;

  dataSetProps: (keyof SelectPropsType)[] = [
    "multiple",
    "placeholder",
    "clearable",
    "showSelected",
    "showArrow",
    "searchable",
    "noResultsText",
    "sortOptions",
    "icon",
    "selectionDisplayType",
    "optionsWrapPreferredWidth",
    "optionsWrapMaxHeight"
  ];

  dataSetElementAttrProps: string[] = [
    "wrapper",
    "searchInput",
    "select"
  ];

  constructor(selectors: string[] | string) {
    if (!Array.isArray(selectors)) {
      selectors = [selectors];
    }

    this.selectors = [...this.selectors, ...selectors];

    const selectInitElements: HTMLElement[] = Array.from(
      document.querySelectorAll(this.selectors.join(","))
    );

    if (document.readyState === "loading") {
      document.addEventListener(
        "DOMContentLoaded",
        () => this.initializeSelect(selectInitElements)
      );
    }
    else {
      this.initializeSelect(selectInitElements);
    }
    
    this.bodyMutationObserver = new MutationObserver((mutations) => {
      document.dispatchEvent(
        new CustomEvent("csDomMutations", {
          bubbles: true,
          detail: mutations
        })
      );
    });
  }

  initializeSelect(selectInitElements: HTMLElement[] | HTMLElement) {
    if (!Array.isArray(selectInitElements)) {
      selectInitElements = [selectInitElements];
    }

    selectInitElements.forEach((element) => {
      if (element.nodeName === "SELECT" && element.parentElement?.classList.contains("cs-select")) {
        return;
      }

      const isSelectNode = element.nodeName === "SELECT";

      let props: SelectPropsType = this.getSelectProps(element);
      let selected: string[];
      let options: OptionType[];
      let emptyOption: OptionType | undefined;

      if (isSelectNode) {
        [selected, options, emptyOption] = this.getOptionsFromSelect(element as HTMLSelectElement);
        props.selectElement = element as HTMLSelectElement;
      }
      else {
        [selected, options, emptyOption] = this.getOptionsFromHtml(element);
      }

      if (!props.placeholder && emptyOption) {
        props.placeholder = emptyOption.label;
      }

      props = { ...props, options, selected };

      const selectWrapper = document.createElement("div");
      element?.parentNode?.insertBefore(selectWrapper, element.nextSibling);

      console.log('props', props);

      ReactDOM.createRoot(selectWrapper).render(
        <CustomizableSelect {...props}/>
      );

      if (isSelectNode) {
        element.removeAttribute("style");
        element.classList.remove("cs-select-init");  
      }
      else {
        element.remove();
      }
    });
  }

  getSelectProps(element: HTMLElement): SelectPropsType {
    const isSelectNode = element.nodeName === "SELECT";
    const attrPropsRegex = new RegExp(`^(${this.dataSetElementAttrProps.join("|")})([A-Z][A-Za-z]+)`);
    const attrPropsData: AttrPropsDataType = {};

    let props: SelectPropsType = { options: [] };

    if (isSelectNode) {
      props.multiple = (element as HTMLSelectElement).multiple;
    }

    let iconSelector: string = "";

    Object.entries(element.dataset).forEach(([key, value]: DataSetEntryType) => {
      if (key === "icon") {
        iconSelector = value as string;
      }
      else if (this.dataSetProps.includes(key as keyof SelectPropsType)) {
        if (value === "true") {
          value = true;
        }
        else if (value === "false") {
          value = false;
        }
        else if (!isNaN(Number(value))) {
          value = Number(value);
        }
        
        props = setObjectProperty(props, key as keyof SelectPropsType, value);
      }
      else {
        const matches = key.match(attrPropsRegex);

        if (matches) {
          const propsKey = `${matches[1]}Attrs` as keyof SelectPropsType;

          if (!attrPropsData[propsKey]) {
            attrPropsData[propsKey] = {};
          }

          let attr = matches[2]
            .replace(/[A-Z]/g, (letter: string) => `-${letter.toLowerCase()}`)
            .slice(1);
          
          if (attr === "class") {
            attr = "className";
          }
          else if (attr === "style") {
            value = inlineStylesToObject(value as string);
          }

          attrPropsData[propsKey][attr] = value;
        }
      }
    });

    Object.entries(attrPropsData).forEach(([key, value]) => {
      props = setObjectProperty(props, key as keyof SelectPropsType, value);
    });
    
    props.icon = this.getIconProp(element, iconSelector);

    return props;
  }

  getIconProp(element: HTMLElement, iconSelector?: string): React.JSX.Element | null {
    let icon: Element | null = null;

    if (iconSelector && iconSelector.length) {
      icon = document.querySelector(iconSelector);
    }
    
    if (!icon) {
      icon = element.querySelector(".cs-select-icon");
    }

    if (icon) {
      const iconReactElement = htmlToReact(icon.outerHTML);
      icon.remove();

      if (React.isValidElement(iconReactElement)) {
        return iconReactElement;
      }
    }

    return null;
  }

  getOptionsFromSelect(element: HTMLSelectElement): OptionsDataType {
    const optionElements: NodeListOf<HTMLOptionElement> = element.querySelectorAll("option");
    const options: OptionType[] = [];
    const selected: string[] = [];
    
    let emptyOption: OptionType | undefined;

    optionElements.forEach((optionElement) => {
      const { dataset: { label }, value, innerHTML, selected: isSelected } = optionElement;

      // Note: HTML option elements can only contain text nodes, so any HTML
      // would need to be encoded for the label or html property.
      const option: OptionType = {
        label: label && label.length ? decodeHtml(label) : stripHtml(innerHTML).result,
        value,
        html: htmlToReact(decodeHtml(innerHTML))
      };

      options.push(option);

      if (!value.length) {
        emptyOption = option;
      }

      if (isSelected) {
        selected.push(value);
      }
    });

    return [selected, options, emptyOption];
  }

  /**
   * Option setup method #2. This method allows us to specify data for each
   * option without using the <select> element, or if we want to use HTML for
   * options in the dropdown.
   * 
   * The HTML element that contains the data for each option should have the
   * CSS class name "emads-select-html." Each child element of the container
   * should represent an option for the dropdown and must specify the option
   * value (as data-value="...") and label (as data-label="..."). Any HTML
   * contained within a child node will be shown for that option when the
   * dropdown is open, but the label will be shown in the selected options
   * summary box. If no HTML is contained within a child node, then the label
   * will be shown for that option when the dropdown is open.
   * 
   * Example setup:
   * 
   * <div class="emads-select-html">
   *   <!-- This option will be used a the placeholder. Since no HTML is
   *   contained within the DIV, the label will be used as the HTML -->
   *   <div data-value="" data-label="Select an option"></div>
   *   <div data-value="option-1" data-label="Option 1" data-selected="true">
   *     <h6>Option 1</h6>
   *     <p>A short description for option 1.</p>
   *   </div>
   *   <div data-value="option-2" data-label="Option 2">
   *     <h6>Option 2</h6>
   *     <p>A short description for option 2.</p>
   *   </div>
   * </div>
   * 
   * The container element can also specify optional arguments used to modify
   * the output of the dropdown. These arguments are:
   * 
   * - The dropdown theme/style:
   *   - Attribute: data-theme="['dark', 'light', or 'outline']"
   *   - Default: "dark"
   * 
   * - The placeholder text if no placeholder is specified as a child of the
   *   container element.
   *   - Attribute: data-placeholder="..."
   *   - Default: "Select"
   * 
   * - The placeholder HTML that will show for the option wwhen the dropdown
   *   is open.
   *   - Attribute: data-placeholder-html="..."
   *   - Default: "None"
   * 
   * - Whether the dropdown should allow multiple options to be selected.
   *   - Attribute: data-multiple="['true', or 'false']"
   *   - Default: "true"
   *  
   * - Whether the selected option(s) can be removed.
   *   - Attribute: data-clearable="['true', or 'false']"
   *   - Default: "true"
   * 
   * - Whether to add a text input to allow the user to search the options
   *   using keywords.
   *   - Attribute: data-searchable="['true', or 'false']"
   *   - Default: "false"
   * 
   * - The placeholder for the search input.
   *   - Attribute: data-search-placeholder="..."
   *   - Default: "Search"
   * 
   * - Whether to show the labels of selected option in the summary box.
   *   - Attribute: data-show-selected="['true', or 'false']"
   *   - Default: "true"
   * 
   * - Whether to show the reset button if "data-multiple" is "true." The reset
   *   button clears all selected options.
   *   - Attribute: data-show-reset="['true', or 'false']"
   *   - Default: "false"
   * 
   * - Whether to show the dropdown arrow or not.
   *   - Attribute: data-show-arrow="['true', or 'false']"
   *   - Default: "true"
   * 
   * - The CSS selector of the SVG icon that will appear in the dropdown.
   *   - Attribute: data-icon="..."
   *   - Default: ""
   * 
   * - Whether the options should be sorted by their label in ascending order.
   *   - Attribute: data-sort="['true', or 'false']"
   *   - Default: "false"
   * 
   * - The minimum width of the options wrapper that shows when the dropdown
   *   is open.
   *   - Attribute: data-options-min-width="..."
   *   - Default: "230"
   * 
   * - The maximum height of the options wrapper that shows when the dropdown
   *   is open.
   *   - Attribute: data-options-max-height="..."
   *   - Default: "300"
   * 
   * @param any element The HTML node that contains child elements with the
   *   data needed to setup the options for the dropdown.
   * 
   * @return void
   */
  getOptionsFromHtml(element: HTMLElement): OptionsDataType {
    const options: OptionType[] = [];
    const selected: string[] = [];
    
    let emptyOption: OptionType | undefined;

    // Collect all child nodes of `element`. Each node contains data used to
    // create the options for the custom dropdown.
    const optionElements: NodeListOf<HTMLElement> = element.querySelectorAll(".cs-select-option");

    optionElements.forEach((optionElement) => {
      // The value of an option's label is retrieved from the `data-label` attribute,
      // so any HTML that is included must be encoded.
      const {
        dataset: {
          label,
          value = "",
          selected: isSelected
        },
        innerHTML
      } = optionElement;

      const option: OptionType = {
        label: label && label.length ? decodeHtml(label) : stripHtml(innerHTML).result,
        value,
        html: htmlToReact(innerHTML)
      };

      options.push(option);

      if (!value.length) {
        emptyOption = option;
      }

      if (isSelected && isSelected === "true") {
        selected.push(value);
      }
    });

    return [selected, options, emptyOption];
  }

  watch(options: MutationObserverInit = {
    childList: true,
    subtree: true,
    attributes: true
  }) {
    if (!this.isWatching) {
      addEventListener(document, "csDomMutations.watcher", (event) => {
        const mutations: MutationRecord[] = (event as CustomEvent).detail;

        mutations.forEach((mutation) => {
          if (mutation.type === "childList") {
            mutation.addedNodes.forEach((node) => {
              if (node.nodeType !== Node.ELEMENT_NODE) {
                return;
              }
      
              const element = node as HTMLElement;
              const elementMatchesSelector = this.selectors.some((selector) => element.matches(selector));
              const selectInitElements = [];

              if (elementMatchesSelector) {
                selectInitElements.push(element);
              }
              else {
                this.selectors.forEach((selector) => {
                  const nodes = element.querySelectorAll(selector);

                  if (nodes.length) {
                    selectInitElements.push(...nodes);
                  }
                });
              }

              if (selectInitElements.length) {
                this.initializeSelect(selectInitElements);
              }
            });
          }
          else if (mutation.type === "attributes") {
            const target = mutation.target as HTMLElement;
            const targetMatchesSelector = this.selectors.some((selector) => target.matches(selector));

            if (targetMatchesSelector) {
              this.initializeSelect(target);
            }
          }
        });
      });
    }
    
    if (this.bodyMutationObserver) {
      console.log('observe');
      this.bodyMutationObserver.observe(document, options);
    }

    this.isWatching = true;

    return this;
  }

  disconnect() {
    if (this.bodyMutationObserver) {
      this.bodyMutationObserver.disconnect();
    }

    removeEventListener(document, "csDomMutations.watcher");

    this.isWatching = false;

    return this;
  }
}

const CustomizableSelectInit = (selectors: string[] | string) => {
  return new Initializer(selectors);
};

export default CustomizableSelectInit;
