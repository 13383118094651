export function isNumeric(value: unknown): boolean {
  return !isNaN(Number(value));
}

export function generateClassList(classArrays: [boolean, string, string?][]): string[] {
  const classes: string[] = [];
  
  classArrays.forEach((classArray) => {
    const [isTrue, trueClass, falseClass] = classArray;

    if (isTrue) {
      classes.push(trueClass);
    }
    else if (falseClass) {
      classes.push(falseClass);
    }
  });

  return classes;
}

export function arrayUnique<T>(array: T[]): T[] {
  return Array.from(new Set(array));
}

export function arrayAddUnique<T>(array: T[], value: T | T[]): T[] {
  if (!(value instanceof Array)) {
    value = [value];
  }

  return arrayUnique([...array, ...value]);
}

export function arrayWithout<T>(array: T[], value: T | T[]): T[] {
  if (!(value instanceof Array)) {
    value = [value];
  }

  return array.filter((item) => !(value as T[]).includes(item));
}

export type EventListenerCallback = (event: MouseEvent | UIEvent | Event) => void;

type EventListenerData = {
  element: Window & typeof globalThis | Document | HTMLElement;
  listeners: EventListenerCallback[];
  listenerOptions: (boolean | AddEventListenerOptions)[];
};

const eventListeners: Record<string, EventListenerData[]> = {};

export function addEventListener(
  element: Window & typeof globalThis | Document | HTMLElement,
  eventKey: string,
  listener: EventListenerCallback,
  options: boolean | AddEventListenerOptions = false
) {
  console.log("addEventListener", element, eventKey);
  element.addEventListener(eventKey.split(".")[0], listener, options);

  if (!eventListeners[eventKey]) {
    eventListeners[eventKey] = [{
      element,
      listeners: [listener],
      listenerOptions: [options]
    }];
  }
  else {
    const elementIndex = eventListeners[eventKey]
      .findIndex(({ element: e }) => e === element);

    if (elementIndex > -1) {
      eventListeners[eventKey][elementIndex].listeners.push(listener);
      eventListeners[eventKey][elementIndex].listenerOptions.push(options);
    }
  }
}

export function removeEventListener(
  element: Window & typeof globalThis | Document | HTMLElement,
  eventKey: string,
  listener?: EventListenerCallback
) {
  if (!eventListeners[eventKey]) {
    return;
  }

  const elementIndex = eventListeners[eventKey].findIndex(({ element: e }) => e === element);

  if (elementIndex > -1) {
    const event = eventKey.split(".")[0];
    const { listeners, listenerOptions } = eventListeners[eventKey][elementIndex];

    if (typeof listener !== "undefined") {
      for (let i = 0; i < listeners.length; i++) {
        if (listeners[i] === listener) {
          element.removeEventListener(event, listener, listenerOptions[i]);
          eventListeners[eventKey][elementIndex].listeners.splice(i, 1);
          eventListeners[eventKey][elementIndex].listenerOptions.splice(i, 1);

          if (!eventListeners[eventKey][elementIndex].listeners.length) {
            eventListeners[eventKey].splice(elementIndex, 1);
            break;
          }
        }
      }
    }
    else {
      listeners.forEach((l, listenerIndex) => {
        element.removeEventListener(event, l, listenerOptions[listenerIndex]);
      });

      eventListeners[eventKey].splice(elementIndex, 1);
    }

    if (!eventListeners[eventKey].length) {
      delete eventListeners[eventKey];
    }
  }
}

export function hasEventListener(
  element: Window & typeof globalThis | Document | HTMLElement,
  eventKey: string,
  listener?: EventListenerCallback
): boolean {
  if (!eventListeners[eventKey]) {
    return false;
  }

  const elementIndex = eventListeners[eventKey].findIndex(({ element: e }) => e === element);

  if (elementIndex > -1) {
    const { listeners } = eventListeners[eventKey][elementIndex];

    if (typeof listener !== "undefined") {
      return listeners.findIndex((l) => l === listener) > -1;
    }
    
    return listeners.length > 0;
  }

  return false;
}

export function toggleClassName(className: string | string[], add: boolean, classList?: string) {
  return add
    ? addClassToString(className, classList)
    : removeClassFromString(className, classList);
}

export function addClassToString(classToAdd: string | string[], className?: string) {
  return arrayAddUnique((className || "").split(" "), classToAdd).join(" ");
}

export function removeClassFromString(classToRemove: string | string[], className?: string) {
  return arrayWithout((className || "").split(" "), classToRemove).join(" ");
}

export function inlineStylesToObject(style: string): Record<string, string> {
  return style
    .replace(/;$/, "")
    .split(";")
    .reduce((acc, pair) => {
      const [key, value] = pair.split(":");
      return { ...acc, [key]: value };
    }, {});
}

export function generateRandomHash(): string {
  return Math.random().toString(36).substring(2, 10);
}

export function setObjectProperty<T, K extends keyof T>(obj: T, key: K, value: T[K]): T {
  return { ...obj, [key]: value };
}

export function omit(obj: {[key: string]: unknown}, keys: string | string[]): object {
  if (!Array.isArray(keys)) {
    keys = [keys];
  }

  return Object.keys(obj)
    .filter((key) => !keys.includes(key))
    .reduce((acc, key) => ({ ...acc, [key]: obj[key] }), {});
}

export const objectsAreEqual = (
  obj1: { [key: string]: any },
  obj2: { [key: string]: any }
): boolean => {
  if (obj1 === obj2) {
    return true;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  return keys1.every((key) => {
    return obj2.hasOwnProperty(key) && obj1[key] === obj2[key];
  });
};

export const createUniqueEventListenerKeys = () => {
  
}