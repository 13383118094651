import React, { SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import { renderToString } from 'react-dom/server';
import { OptionType, SelectPropsType, SelectSettingsType, SelectContextType } from './types';
import { generateClassList, generateRandomHash, toggleClassName, addEventListener, removeEventListener, arrayWithout, arrayAddUnique, hasEventListener, objectsAreEqual, setObjectProperty } from '../../helpers';
import Options from './Options';
import SelectElement from './SelectElement';
import SelectedOptions from './SelectedOptions';
import { defaultSelectSettings, SelectSettingsContext } from '../../contexts/SelectSettingsContext';
import IconChevronDown from '../icons/IconChevronDown';
import ResetButton from './ResetButton';
import SelectedOptionImages from './SelectedOptionImages';
import ReusableIcons from '../icons/ReusableIcons';
import { defaultSelectProps } from '../../contexts/SelectSettingsContext';
import { OpenState, HorizontalAlign, VerticalPosition } from '../../constants';

const nativeSelectValueSetter = Object.getOwnPropertyDescriptor(
  HTMLSelectElement.prototype,
  "value"
);

type Entries<T> = { [K in keyof T]: [K, T[K]] }[keyof T];
function ObjectEntries<T extends object>(t: T): Entries<T>[] {
  return Object.entries(t) as any;
}
export default function CustomizableSelect(props: SelectPropsType): React.JSX.Element | null {
  const [settings, updateSettings] = useState<SelectSettingsType>({
    ...defaultSelectSettings,
    ...props
  });

  const {
    init,
    uniqueHash,
    options,
    selected,
    wrapperElement,
    wrapperAttrs,
    selectElement,
    selectAttrs,
    usesExistingSelectElement,
    multiple,
    placeholder,
    clearable,
    showSelected,
    showArrow,
    searchable,
    sortOptions,
    icon,
    useCustomIcons,
    isNavigatingWithKeyboard,
    openState,
    searchInputWrapElement,
    isSearching,
    searchInputValue,
    searchMatchCount,
    focusedOption,
    optionsScrollElement,
    selectHandleElement,
    resetOptionsWrapTimeout,
    optionsWrapPreferredWidth,
    optionsWrapMaxHeight,
    hasSelectedOptionImages,
    optionsWrapRepositionObserver,
    dropdownRepositionData,
    selectLabelElement,
    updatedFromSelectChange,
    preventOnChangeEvent,
    optionsWrapPosition,
    events
  } = settings;

  let { optionsWrapperStyle, optionsWrapperClassList } = settings;

  const wrapperRef = useRef(null);
  const selectHandleRef = useRef(null);

  const IS_OPENED = openState === OpenState.OPENED;
  const IS_CLOSING = openState === OpenState.CLOSING;
  const IS_CLOSED = openState === OpenState.CLOSED;

  console.log(uniqueHash, 'IS_OPENED', IS_OPENED, 'IS_CLOSING', IS_CLOSING, 'IS_CLOSED', IS_CLOSED);

  const onSelectChange = useCallback((event: React.ChangeEvent<HTMLSelectElement> | Event | CustomEvent | SyntheticEvent<HTMLSelectElement>) => {
    console.log(uniqueHash, 'onSelectChange', event, (event.target as HTMLSelectElement).value);
    let eventDetail;
    
    if (Object.hasOwn(event, "nativeEvent")) {
      const { nativeEvent } = event as SyntheticEvent<HTMLSelectElement>;
      eventDetail = (nativeEvent as CustomEvent).detail;
      console.log(uniqueHash, 'DETAIL 1', eventDetail);
    }
    
    if (!eventDetail && event instanceof CustomEvent) {
      eventDetail = event.detail;
      console.log(uniqueHash, 'DETAIL 2', eventDetail);
    }

    if (eventDetail instanceof Object && eventDetail.csSelect) {
      return;
    }

    const { options } = event.target as HTMLSelectElement;

    const selected = Array.from(options)
      .filter((option) => option.selected)
      .map((option) => option.value);

    updateSelectedValues(selected, true);
  }, []);

  const updateSelectedValues = (
    selected: string[],
    updatedFromSelectChange: boolean = false,
    preventOnChangeEvent: boolean = false
  ) => {
    updateSettings((settings) => ({
      ...settings,
      selected,
      updatedFromSelectChange,
      preventOnChangeEvent
    }));
  };

  useEffect(() => {
    let adjustedOptions = [...options];

    const emptyOption = adjustedOptions.find((option) => option.value === "");
    const hasEmptyOption = typeof emptyOption !== "undefined";

    let adjustedPlaceholder = placeholder;

    if (placeholder === defaultSelectProps.placeholder && hasEmptyOption && emptyOption.label.length) {
      adjustedPlaceholder = emptyOption.label;
    }

    if (clearable && !hasEmptyOption) {
      adjustedOptions = [
        { label: "", value: "", html: "" },
        ...adjustedOptions
      ];
    }

    const tempDiv = document.createElement("div");
    
    adjustedOptions = adjustedOptions.map((data) => {
      let { html } = data;

      if (typeof html === "function") {
        const Html = html as React.FC;
        html = <Html/>;
      }

      if (typeof html !== "string") {
        html = renderToString(html);
      }
      
      tempDiv.innerHTML = html;
      
      const searchableElement = tempDiv.querySelector(".-searchable") || tempDiv;

      const selectedImageSrc: string | undefined = (
        tempDiv.querySelector("img.cs-option-image") as HTMLImageElement
      )?.src || (
        tempDiv.querySelector("[data-option-image]") as HTMLElement
      )?.dataset?.selectedImageSrc;

      return {
        ...data,
        html,
        selectedImageSrc,
        searchableText: searchableElement.textContent || ""
      };
    });

    tempDiv.remove();

    if (sortOptions) {
      adjustedOptions = adjustedOptions.sort((a, b) => (
        a.label === b.label ? 0 : a.label > b.label ? 1 : -1
      ));
    }

    const adjustedOptionValues = adjustedOptions.map((option) => option.value);
    
    let adjustedSelectedValues = (selected || []).filter((value) => (
      adjustedOptionValues.includes(value)
    ));

    if (!multiple && !clearable && !adjustedSelectedValues.length && adjustedOptionValues.length) {
      adjustedSelectedValues = [adjustedOptionValues[0]];
    }

    const isEmpty = !adjustedSelectedValues.length;

    const hasSelectedOptionImages = adjustedOptions.some(({ selectedImageSrc }) => selectedImageSrc);

    let selectedOptionImageSrc: string | null | undefined = null;

    if (!multiple && hasSelectedOptionImages && !isEmpty) {
      const selectedOption = adjustedOptions.find(({ value }) => adjustedSelectedValues[0] === value);
      
      if (selectedOption) {
        selectedOptionImageSrc = selectedOption.selectedImageSrc;
      }
    }
    
    let wrapperClassList = generateClassList([
      [multiple, "-multiple", "-single"],
      [searchable, "-searchable"],
      [clearable, "-clearable"],
      [showSelected, "-show-selected"],
      [showArrow, "-arrow"],
      [!!icon || hasSelectedOptionImages, "-show-option-images"]
    ]);

    wrapperClassList = ["cs-select", ...wrapperClassList];

    if (wrapperAttrs.className) {
      wrapperClassList.push(wrapperAttrs.className);
    }
    
    wrapperAttrs.className = wrapperClassList.join(" ");
    
    const uniqueHash = generateRandomHash();

    if (!wrapperAttrs.id) {
      wrapperAttrs.id = `cs-select-${uniqueHash}`;
    }

    const adjustedEvents = { ...events };

    ObjectEntries(adjustedEvents).forEach(([key, value]) => {
      adjustedEvents[key] = `${value}-${uniqueHash}`;
    });

    const optionsWrapRepositionObserver = new MutationObserver(() => {
      document.dispatchEvent(
        new CustomEvent(adjustedEvents.csOptionsWrapReposition, { bubbles: true })
      );
    });

    if (!useCustomIcons && !document.getElementById("cs-icons")) {
      document.body.insertAdjacentHTML("beforeend", renderToString(<ReusableIcons/>));
    }

    console.log(uniqueHash, 'set init settings', wrapperRef, wrapperRef?.current);

    updateSettings((settings) => ({
      ...settings,
      ...props,
      init: true,
      uniqueHash,
      options: adjustedOptions,
      placeholder: adjustedPlaceholder,
      usesExistingSelectElement: !!selectElement,
      isEmpty,
      wrapperElement: wrapperRef?.current,
      wrapperAttrs,
      preventScrollOnArrowPress,
      hasSelectedOptionImages,
      selectedOptionImageSrc,
      optionsWrapRepositionObserver,
      events: adjustedEvents
    }));

    updateSelectedValues(adjustedSelectedValues, false, true);
  }, []);

  useEffect(() => {
    if (!init) {
      return;
    }

    if (usesExistingSelectElement && selectElement) {
      addEventListener(selectElement, events.changeCsSelect, onSelectChange);
      selectElement.tabIndex = -1;
      wrapperElement?.insertBefore(selectElement, wrapperElement.firstChild);
    }
    
    console.log(uniqueHash, 'set select label click listener', settings);

    const selectId = selectElement?.id || selectAttrs.id;
    let selectLabel: HTMLLabelElement | null | undefined = null;

    if (selectId) {
      selectLabel = document.querySelector(`label[for="${selectId}"]`) as HTMLLabelElement;
    }
    
    if (!selectLabel) {
      selectLabel = wrapperElement?.closest("label");
    }

    if (selectLabel) {
      console.log(uniqueHash, 'selectLabel', selectLabel);
      addEventListener(selectLabel, events.clickShowSelectOptions, onSelectLabelClick);
    }

    updateSettings((settings) => ({
      ...settings,
      selectHandleElement: selectHandleRef?.current,
      selectLabelElement: selectLabel
    }));
  }, [init]);

  useEffect(() => {
    if (!init || updatedFromSelectChange && usesExistingSelectElement || preventOnChangeEvent) {
      return;
    }
    
    console.log(uniqueHash, 'SET SELECT VALUE', selectElement, selected);

    if (usesExistingSelectElement) {
      let valueToSet: string | string[] = [...selected];

      if (!multiple && Array.isArray(selected)) {
        valueToSet = selected[0];
      }

      nativeSelectValueSetter?.set?.call(selectElement, valueToSet);
    }

    selectElement?.dispatchEvent(
      new CustomEvent("change", {
        bubbles: true,
        detail: {
          csSelect: true
        }
      })
    );

    adjustOptionsWrapPosition();
  }, [selected]);

  useEffect(() => {
    if (!init) {
      return;
    }
    
    const isEmpty = selected.filter((value) => value.length).length === 0;

    wrapperAttrs.className = toggleClassName("-empty", isEmpty, wrapperAttrs?.className);
    wrapperAttrs.className = toggleClassName("-open", IS_OPENED, wrapperAttrs?.className);

    wrapperAttrs.className = toggleClassName(
      "-navigate-with-keyboard",
      isNavigatingWithKeyboard,
      wrapperAttrs?.className
    );

    updateSettings((settings) => ({
      ...settings,
      wrapperAttrs,
      isEmpty,
      isNavigatingWithKeyboard
    }));
  }, [selected, IS_OPENED, isNavigatingWithKeyboard]);

  const preventScrollOnArrowPress = (event: React.KeyboardEvent) => {
    const { key } = event;
    const visibleOptionCount = isSearching ? searchMatchCount : options.length;

    if (IS_OPENED && visibleOptionCount > 0 && (key === 'ArrowDown' || key === 'ArrowUp')) {
      event.preventDefault();
    }
  };

  const onSelectHandleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    preventScrollOnArrowPress(event);

    const { key } = event;

    if (key === "Enter" || key === " ") {
      event.preventDefault();

      const element = event.target as HTMLElement;
      
      if (!element.classList.contains("cs-select-clear") && !element.classList.contains("cs-select-reset")) {
        toggleOptionsWrap();
      }
    }
    else {
      arrowNavigateOptions(event);
    }
  };

  useEffect(() => {
    adjustOptionsWrapPosition();
  }, [searchMatchCount]);
  
  const adjustOptionsWrapPosition = useCallback((onOpen: boolean = false, onClose: boolean = false) => {
    console.log(uniqueHash, 'adjustOptionsWrapPosition');

    if (!wrapperElement || !optionsScrollElement) {
      return;
    }

    const {
      width: wrapperWidth,
      height: wrapperHeight,
      top: wrapperTop,
      left: wrapperLeft
    } = wrapperElement.getBoundingClientRect();

    let optionsWrapWidth = Math.max(optionsWrapPreferredWidth, wrapperWidth);
    let searchInputWrapHeight = 0;

    if (searchable && searchInputWrapElement) {
      searchInputWrapHeight = searchInputWrapElement.getBoundingClientRect().height;
    }

    let optionsWrapHeight = Math.min(optionsWrapMaxHeight, optionsScrollElement.scrollHeight + searchInputWrapHeight);

    const optionsWrapMaxHeight75Percent = optionsWrapMaxHeight * .75;
    const gapSize = 4;
    const doubleGapSize = 2 * gapSize;
    const spaceTop = wrapperTop - doubleGapSize;
    const spaceBottom = window.innerHeight - (wrapperTop + wrapperHeight + doubleGapSize);

    let optionsWrapperNewStyle: typeof optionsWrapperStyle = { ...optionsWrapperStyle };

    let {
      vertical: optionsWrapVerticalPosition,
      horizontal: optionsWrapHorizontalAlign,
      lastCenterOffsetTop
    } = optionsWrapPosition;

    let optionsWrapTop;

    const isClosingState = onClose || IS_CLOSING;

    if (isClosingState) {
      const currentOptionsWrapHeight = parseFloat(optionsWrapperStyle?.height as string || optionsWrapHeight.toString());

      switch (optionsWrapVerticalPosition) {
        case VerticalPosition.TOP:
          optionsWrapTop = wrapperTop - gapSize - currentOptionsWrapHeight;
          break;

        case VerticalPosition.BOTTOM:
          optionsWrapTop = wrapperTop + wrapperHeight + gapSize;
          break;

        case VerticalPosition.CENTER:
          optionsWrapTop = wrapperTop - lastCenterOffsetTop;
          break;
      }

      optionsWrapperNewStyle.top = `${optionsWrapTop}px`;
    }
    else {
      if (spaceTop > optionsWrapMaxHeight75Percent || spaceBottom > optionsWrapMaxHeight75Percent) {
        if (spaceBottom < optionsWrapMaxHeight75Percent && spaceTop > spaceBottom) {
          optionsWrapVerticalPosition = VerticalPosition.TOP;
          optionsWrapHeight = Math.min(optionsWrapHeight, spaceTop);
          optionsWrapTop = wrapperTop - gapSize - optionsWrapHeight;
        }
        else {
          optionsWrapVerticalPosition = VerticalPosition.BOTTOM;
          optionsWrapHeight = Math.min(optionsWrapHeight, spaceBottom);
          optionsWrapTop = wrapperTop + wrapperHeight + gapSize;
        }
      }
      else {
        optionsWrapVerticalPosition = VerticalPosition.CENTER;
        optionsWrapHeight = Math.min(optionsWrapHeight, window.innerHeight - doubleGapSize);
        optionsWrapTop = Math.max(
          wrapperTop - ((optionsWrapHeight - wrapperHeight) / 2),
          gapSize
        );

        const pixelsBeyondBottom = optionsWrapTop + optionsWrapHeight - (window.innerHeight - gapSize);

        if (pixelsBeyondBottom > 0) {
          optionsWrapTop -= pixelsBeyondBottom;
        }

        lastCenterOffsetTop = wrapperTop - optionsWrapTop;
      }
      
      optionsWrapperClassList = arrayWithout(optionsWrapperClassList, [
        VerticalPosition.TOP,
        VerticalPosition.BOTTOM,
        VerticalPosition.CENTER
      ]);

      optionsWrapperClassList.push(optionsWrapVerticalPosition);

      optionsWrapperNewStyle = {
        ...optionsWrapperStyle,
        height: `${optionsWrapHeight}px`,
        top: `${optionsWrapTop}px`
      };
    }

    const spaceLeft = (wrapperLeft + wrapperWidth) - gapSize;
    const spaceRight = window.innerWidth - wrapperLeft - gapSize;

    let optionsWrapLeft;

    const maintainHorizontalAlignRight = isClosingState && optionsWrapHorizontalAlign === HorizontalAlign.RIGHT;

    if (maintainHorizontalAlignRight || spaceRight < optionsWrapPreferredWidth && spaceLeft > spaceRight) {
      optionsWrapHorizontalAlign = HorizontalAlign.RIGHT;
      optionsWrapWidth = Math.min(optionsWrapPreferredWidth, spaceLeft);
      optionsWrapLeft = (wrapperLeft + wrapperWidth) - optionsWrapWidth;
    }
    else {
      optionsWrapHorizontalAlign = HorizontalAlign.LEFT;
      optionsWrapWidth = Math.min(optionsWrapPreferredWidth, spaceRight);
      optionsWrapLeft = wrapperLeft;
    }

    optionsWrapWidth = Math.min(
      Math.max(optionsWrapWidth, wrapperWidth),
      window.innerWidth - doubleGapSize
    );

    if (optionsWrapLeft < gapSize) {
      optionsWrapLeft = gapSize;
    }
    else if (optionsWrapLeft + optionsWrapWidth > window.innerWidth - gapSize) {
      optionsWrapLeft = window.innerWidth - gapSize - optionsWrapWidth;
    }

    optionsWrapperNewStyle = {
      ...optionsWrapperNewStyle,
      width: `${optionsWrapWidth}px`,
      left: `${optionsWrapLeft}px`
    };
    
    optionsWrapperClassList = arrayWithout(optionsWrapperClassList, [
      HorizontalAlign.LEFT,
      HorizontalAlign.RIGHT
    ]);

    optionsWrapperClassList.push(optionsWrapHorizontalAlign);

    if (!onOpen && IS_CLOSED) {
      optionsWrapperNewStyle.height = 0;
    }
    
    if (!objectsAreEqual(optionsWrapperNewStyle, optionsWrapperStyle)) {
      updateSettings((settings) => ({
        ...settings,
        optionsWrapperClassList,
        optionsWrapperStyle: optionsWrapperNewStyle,
        optionsWrapPosition: {
          vertical: optionsWrapVerticalPosition,
          horizontal: optionsWrapHorizontalAlign,
          lastCenterOffsetTop
        }
      }));
    }
  }, [
    openState,
    wrapperElement,
    optionsScrollElement,
    searchInputWrapElement,
    optionsWrapperClassList,
    optionsWrapperStyle
  ]);

  const resetSearch = useCallback(() => {
    console.log(uniqueHash, 'reset search');

    updateSettings((settings) => ({
      ...settings,
      isSearching: false,
      searchInputValue: "",
      searchMatchCount: 0
    }));
  }, []);

  const resetOptionsWrap = useCallback(() => {
    console.log(uniqueHash, 'resetOptionsWrap');

    removeEventListener(document, events.csOptionsWrapReposition);
    removeEventListener(window, events.resizeOptionsWrap);

    console.log(uniqueHash, 'remove wheel.hideOptionsWrap');
    removeEventListener(window, events.wheelHideOptionsWrap);
    removeEventListener(window, events.scrollAdjustOptionsWrapPosition);

    if (optionsWrapRepositionObserver) {
      optionsWrapRepositionObserver.disconnect();
    }

    optionsWrapperStyle = {
      ...optionsWrapperStyle,
      height: 0
    };

    if (optionsScrollElement) {
      optionsScrollElement.scrollTop = 0;
    }

    resetSearch();

    updateSettings((settings) => ({
      ...settings,
      optionsWrapperStyle,
      openState: OpenState.CLOSED
    }));
  }, [optionsWrapperStyle, optionsScrollElement, resetSearch]);

  const hideOptionsWrap = useCallback(() => {
    console.log(uniqueHash, 'hideOptionsWrap');

    removeEventListener(document, events.mousedownOutsideOptionsWrap);

    const resetOptionsWrapTimeout = setTimeout(resetOptionsWrap, 110);

    updateSettings((settings) => ({
      ...settings,
      optionTabIndex: -1,
      resetOptionsWrapTimeout,
      openState: OpenState.CLOSING,
      focusedOption: null,
      isNavigatingWithKeyboard: false
    }));
  }, [resetOptionsWrap]);

  const showSearchInputWrapShadow = (show: boolean = true) => {
    let { optionsWrapperClassList } = settings;

    if (show) {
      optionsWrapperClassList = arrayAddUnique(optionsWrapperClassList, "-scrolled");
    }
    else {
      optionsWrapperClassList = arrayWithout(optionsWrapperClassList, "-scrolled");
    }

    updateSettings((settings) => ({
      ...settings,
      optionsWrapperClassList
    }));
  }

  const onClickOutsideOptions = useCallback((event: MouseEvent | Event) => {
    console.log(uniqueHash, 'onClickOutsideOptions', IS_OPENED, event?.target);

    if (!IS_OPENED) {
      return;
    }

    const element = event?.target as HTMLElement;

    if (selectLabelElement && (selectLabelElement === element || selectLabelElement.contains(element))) {
      return;
    }
    
    if (wrapperElement !== element && !wrapperElement?.contains(element) && document.contains(element)) {
      removeEventListener(document, events.mousedownOutsideOptionsWrap);
      console.log(uniqueHash, 'onClickOutsideOptions - hideOptionsWrap');
      hideOptionsWrap();
    }
  }, [IS_OPENED, wrapperElement, selectLabelElement, hideOptionsWrap]);

  const repositionOptionsWrap = useCallback(() => {
    const {
      wrapperWidth,
      wrapperHeight,
      wrapperTop,
      wrapperLeft
    } = dropdownRepositionData;

    if (wrapperElement) {
      const { width, height, top, left } = wrapperElement.getBoundingClientRect();

      if (width !== wrapperWidth || height !== wrapperHeight || top !== wrapperTop || left !== wrapperLeft) {
        console.log(uniqueHash, 'adjustOptionsWrapPosition - repositionOptionsWrap');
        adjustOptionsWrapPosition();

        updateSettings((settings) => ({
          ...settings,
          dropdownRepositionData: {
            wrapperWidth: width,
            wrapperHeight: height,
            wrapperTop: top,
            wrapperLeft: left
          }
        }));
      }
    }
  }, [wrapperElement, dropdownRepositionData, adjustOptionsWrapPosition]);

  useEffect(() => {
    const eventKey = events.csOptionsWrapReposition;

    if (hasEventListener(document, eventKey)) {
      removeEventListener(document, eventKey);
      addEventListener(document, eventKey, repositionOptionsWrap);
    }
  }, [repositionOptionsWrap]);

  const onWindowResize = useCallback(() => {
    console.log(uniqueHash, 'adjustOptionsWrapPosition - onWindowResize');
    adjustOptionsWrapPosition(false, true);

    if (IS_OPENED) {
      hideOptionsWrap();
    }
  }, [IS_OPENED, adjustOptionsWrapPosition, hideOptionsWrap]);

  const onWheelHideOptionsWrap = useCallback((event: Event) => {
    const element = event?.target as HTMLElement;

    if (
      IS_OPENED
      && optionsScrollElement !== element
      && !optionsScrollElement?.contains(element)
      && document.contains(element)
    ) {
      removeEventListener(window, events.wheelHideOptionsWrap);
      hideOptionsWrap();
    }
  }, [IS_OPENED, optionsScrollElement, hideOptionsWrap]);

  useEffect(() => {
    const resizeEventKey = events.resizeOptionsWrap;

    if (hasEventListener(window, resizeEventKey)) {
      removeEventListener(window, resizeEventKey);
      addEventListener(window, resizeEventKey, onWindowResize);
    }
  }, [onWindowResize]);
  
  useEffect(() => {
    const eventKey = events.mousedownOutsideOptionsWrap;

    if (hasEventListener(document, eventKey)) {
      removeEventListener(document, eventKey);
      addEventListener(document, eventKey, onClickOutsideOptions);
    }
  }, [onClickOutsideOptions]);

  useEffect(() => {
    const eventKey = events.wheelHideOptionsWrap;

    if (hasEventListener(window, eventKey)) {
      removeEventListener(window, eventKey);
      addEventListener(window, eventKey, onWheelHideOptionsWrap);
    }
  }, [onWheelHideOptionsWrap]);

  const onScrollAdjustOptionsWrapPosition = useCallback((event: Event) => {
    if ((event.target as HTMLElement)?.contains(wrapperElement)) {
      adjustOptionsWrapPosition(false, true);
    }
  }, [adjustOptionsWrapPosition])

  useEffect(() => {
    const eventKey = events.scrollAdjustOptionsWrapPosition;

    if (hasEventListener(window, eventKey)) {
      removeEventListener(window, eventKey);
      addEventListener(window, eventKey, onScrollAdjustOptionsWrapPosition, true);
    }
  }, [onScrollAdjustOptionsWrapPosition]);

  const showOptionsWrap = () => {
    if (IS_OPENED) {
      return;
    }

    clearTimeout(resetOptionsWrapTimeout);

    console.log(uniqueHash, 'adjustOptionsWrapPosition - showOptionsWrap');
    adjustOptionsWrapPosition(true);

    if (optionsWrapRepositionObserver) {
      addEventListener(document, events.csOptionsWrapReposition, repositionOptionsWrap);

      optionsWrapRepositionObserver.observe(document.body, {
        childList: true,
        subtree: true,
        attributes: true
      });
    }

    addEventListener(document, events.mousedownOutsideOptionsWrap, onClickOutsideOptions);
    addEventListener(window, events.wheelHideOptionsWrap, onWheelHideOptionsWrap);
    addEventListener(window, events.scrollAdjustOptionsWrapPosition, onScrollAdjustOptionsWrapPosition, true);
    addEventListener(window, events.resizeOptionsWrap, onWindowResize);

    updateSettings((settings) => ({
      ...settings,
      openState: OpenState.OPENED,
      optionTabIndex: 0
    }));
  };

  const onSelectLabelClick = (event: MouseEvent | Event) => {
    const element = event?.target as HTMLElement;
    console.log(uniqueHash, 'onSelectLabelClick');
    
    if (selectLabelElement && wrapperElement) {
      if ((selectLabelElement === element || selectLabelElement.contains(element)) && wrapperElement !== element && !wrapperElement.contains(element)) {
        console.log(uniqueHash, 'onSelectLabelClick showOptionsWrap');
        showOptionsWrap();
      }
    }
  };

  useEffect(() => {
    const eventKey = events.clickShowSelectOptions;

    if (selectLabelElement && hasEventListener(selectLabelElement, eventKey)) {
      removeEventListener(selectLabelElement, eventKey);
      addEventListener(selectLabelElement, eventKey, onSelectLabelClick);
    }
  }, [selectLabelElement, onSelectLabelClick, showOptionsWrap]);

  const getVisibleOptions = (): OptionType[] => {
    let visibleOptions = [...options];

    if (isSearching) {
      visibleOptions = visibleOptions
        .filter(({ isSearchMatch }) => isSearchMatch)
        .sort((optionA, optionB) => {
          const { searchMatchOrder: a } = optionA;
          const { searchMatchOrder: b } = optionB;
          return a === b ? 0 : a && b && a > b ? 1 : -1;
        });
    }
    
    return visibleOptions.filter(({ label, html }) => (
      (html as string).length > 0
    ));
  };

  const getSelectedOptions = (): OptionType[] => {
    return options.filter(({ value }) => selected.includes(value));
  };

  const arrowNavigateOptions = (event: React.KeyboardEvent<HTMLButtonElement | HTMLDivElement>) => {
    const visibleOptions = getVisibleOptions();

    console.log(uniqueHash, 'arrowNavigateOptions 1');

    if (!optionsScrollElement || !IS_OPENED || !visibleOptions.length) {
      return;
    }

    console.log(uniqueHash, 'arrowNavigateOptions 2', event.key);

    const { key } = event;
    let isArrowDown = key === "ArrowDown";
    const isArrowUp = key === "ArrowUp";

    const optionElements: Element[] = Array.from(
      optionsScrollElement.children
    );

    console.log(uniqueHash, 'optionElements', optionElements);
    console.log(uniqueHash, 'focusedOption', focusedOption);

    let newFocusedOption: Element | null | undefined = focusedOption;

    if (!newFocusedOption && document.activeElement) {
      newFocusedOption = optionElements.find((option) => option === document.activeElement?.parentNode);
    }

    if (isArrowDown) {
      const firstOption = optionElements[0];

      if (!newFocusedOption) {
        newFocusedOption = firstOption;
      }
      else {
        const nextOption = newFocusedOption.nextElementSibling as HTMLDivElement;

        if (nextOption) {
          newFocusedOption = nextOption;
        }
        else {
          newFocusedOption = firstOption;
        }
      }
    }
    else if (isArrowUp) {
      const lastOption = optionElements.at(-1);

      if (!newFocusedOption) {
        newFocusedOption = lastOption;
      }
      else {
        const prevOption = newFocusedOption.previousElementSibling as HTMLDivElement;

        if (prevOption) {
          newFocusedOption = prevOption;
        }
        else {
          newFocusedOption = lastOption;
        }
      }
    }
    else if (key === "Tab") {
      if (newFocusedOption) {
        const nextOption = newFocusedOption.nextElementSibling as HTMLDivElement;

        if (nextOption) {
          newFocusedOption = nextOption;
          isArrowDown = true;
          event.preventDefault();
        }
        else {
          hideOptionsWrap();
        }
      }
    }
    else if (key === "Escape") {
      hideOptionsWrap();
      selectHandleElement?.focus();

      return;
    }
    else {
      return;
    }

    console.log(uniqueHash, 'newFocusedOption', newFocusedOption);
    
    if (isArrowDown || isArrowUp) {
      const button = newFocusedOption?.querySelector("button");
      
      if (newFocusedOption && button) {
        let scrollTop = optionsScrollElement.scrollTop;

        const scrollPaddingTop = parseFloat(window.getComputedStyle(optionsScrollElement).getPropertyValue("padding-top"));
        const rowGap = parseFloat(window.getComputedStyle(optionsScrollElement).getPropertyValue("row-gap"));
        const scrollViewVisibleAreaBottom = scrollTop + optionsScrollElement.getBoundingClientRect().height;
        const newFocusedOptionIndex = optionElements.indexOf(newFocusedOption);

        let newFocusedOptionTop = scrollPaddingTop + (newFocusedOptionIndex * rowGap);

        for (const option of optionElements) {
          if (option === newFocusedOption) {
            break;
          }

          newFocusedOptionTop += option.getBoundingClientRect().height;
        }

        const newFocusedOptionBottom = newFocusedOptionTop + newFocusedOption.getBoundingClientRect().height;
        const isVisibleTop = newFocusedOptionTop >= scrollTop;
        const isVisibleBottom = newFocusedOptionBottom <= scrollViewVisibleAreaBottom;

        console.log(uniqueHash, {
          scrollPaddingTop,
          scrollViewVisibleAreaBottom,
          newFocusedOptionTop,
          newFocusedOptionBottom,
          isVisibleTop,
          isVisibleBottom
        })

        button.focus({ preventScroll: true });

        if (isArrowDown) {
          if (!newFocusedOption.previousElementSibling) {
            scrollTop = 0;
          }
          else if (!isVisibleBottom) {
            scrollTop = newFocusedOptionTop
              - optionsScrollElement.getBoundingClientRect().height
              + newFocusedOption.getBoundingClientRect().height
              + scrollPaddingTop;
          }
        }
        else if (isArrowUp) {
          if (!newFocusedOption.nextElementSibling) {
            scrollTop = optionsScrollElement.scrollHeight;
          }
          else if (!isVisibleTop) {
            scrollTop = newFocusedOptionTop - scrollPaddingTop;
          }
        }

        console.log(uniqueHash, 'optionsScrollElement.scrollTop', Math.round(scrollTop * 2) / 2);

        optionsScrollElement.scrollTop = Math.round(scrollTop * 2) / 2;
      }
    }

    console.log(uniqueHash, 'SET focusedOption', newFocusedOption);

    updateSettings((settings) => ({
      ...settings,
      isNavigatingWithKeyboard: true,
      focusedOption: newFocusedOption as HTMLDivElement
    }));
  }

  const toggleOptionsWrap = (event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    console.log(uniqueHash, 'toggleOptionsWrap');

    if (event && event?.target) {
      const classList = (event.target as HTMLElement).classList;
      
      if (classList.contains('cs-select-clear') || classList.contains('cs-select-reset')) {
        return;
      }
    }
    
    if (!IS_OPENED) {
      showOptionsWrap();
    }
    else {
      hideOptionsWrap();
    }
  }

  const removeSelectedOption = (event: React.MouseEvent | React.KeyboardEvent) => {
    const { type } = event;

    if (type === "keydown") {
      const { key } = event as React.KeyboardEvent;
      
      if (key !== "Enter" && key !== " ") {
        return;
      }
    }

    event.preventDefault();

    const { value } = event.currentTarget as HTMLButtonElement;
    const updatedSelections = selected.filter((selectedValue) => selectedValue !== value);

    console.log(uniqueHash, 'removeSelectedOption');

    if (multiple) {
      setTimeout(adjustOptionsWrapPosition, 0);
    }

    updateSettings((settings) => ({
      ...settings,
      selectedOptionImageSrc: null
    }));

    updateSelectedValues(updatedSelections);

    selectHandleElement?.focus();
  }

  const contextValue: SelectContextType = {
    settings,
    updateSettings,
    showOptionsWrap,
    hideOptionsWrap,
    toggleOptionsWrap,
    adjustOptionsWrapPosition,
    arrowNavigateOptions,
    getVisibleOptions,
    getSelectedOptions,
    resetSearch,
    showSearchInputWrapShadow,
    preventScrollOnArrowPress,
    removeSelectedOption,
    updateSelectedValues,
    IS_OPENED,
    IS_CLOSING,
    IS_CLOSED
  };

  return (
    <SelectSettingsContext.Provider value={contextValue}>
      <div {...wrapperAttrs} ref={wrapperRef}>
        {init && (
          <>
            {!usesExistingSelectElement && <SelectElement onSelectChange={onSelectChange}/>}
            <div
              ref={selectHandleRef}
              className="cs-select-handle"
              onClick={toggleOptionsWrap}
              onKeyDown={onSelectHandleKeyDown}
              onKeyUp={preventScrollOnArrowPress}
              onFocus={() => adjustOptionsWrapPosition()}
              tabIndex={0}
            >
              {(!!icon || hasSelectedOptionImages) && <SelectedOptionImages/>}
              {showSelected && placeholder.length > 0 && (
                <div className={"cs-select-placeholder"}>
                  <span>{placeholder}</span>
                </div>
              )}
              {showSelected && <SelectedOptions/>}
              {clearable && <ResetButton/>}
              {showArrow && (
                <button type="button" className={"cs-select-arrow"} tabIndex={-1}>
                  <IconChevronDown/>
                </button>
              )}
            </div>
            <Options/>
          </>
        )}
      </div>
    </SelectSettingsContext.Provider>
  );
}