import React, { useContext, useEffect, useRef, useCallback } from "react";
import { arrayAddUnique, arrayWithout, addEventListener, removeEventListener } from "../../helpers";
import SearchInput from "./SearchInput";
import { SelectSettingsContext } from "../../contexts/SelectSettingsContext";
import IconCheck from "../icons/IconCheckmark";
import { SelectSettingsType } from "./types";

export default function Options(): React.JSX.Element {
  const {
    settings,
    updateSettings,
    hideOptionsWrap,
    adjustOptionsWrapPosition,
    arrowNavigateOptions,
    getVisibleOptions,
    preventScrollOnArrowPress,
    showSearchInputWrapShadow,
    updateSelectedValues,
    IS_OPENED
  } = useContext(SelectSettingsContext);
  
  const {
    selected,
    multiple,
    searchable,
    isSearching,
    selectHandleElement,
    optionsWrapperClassList,
    focusedOption,
    options,
    hasSelectedOptionImages,
    searchMatchCount,
    noOptionsText,
    noResultsText
  } = settings;

  const { optionsWrapperStyle } = settings;

  const optionsWrapRef = useRef(null);
  const optionsScrollRef = useRef(null);

  useEffect(() => {
    console.log('set options settings');

    updateSettings((settings): SelectSettingsType => ({
      ...settings,
      optionsWrapperElement: optionsWrapRef?.current,
      optionsScrollElement: optionsScrollRef?.current
    }));
  }, []);

  const toggleSelectOption = (event: React.MouseEvent<HTMLButtonElement>) => {
    const option = event.currentTarget;
    const { value } = option;
    
    let updatedSelections: string[];
    
    if (multiple) {
      if (selected?.includes(value)) {
        updatedSelections = arrayWithout(selected, value);
      }
      else {
        updatedSelections = arrayAddUnique(selected, value);
      }
    }
    else {
      updatedSelections = [value];
    }

    console.log('toggleSelectOption', multiple);

    if (!multiple) {
      hideOptionsWrap();
      selectHandleElement?.focus();
    }
    else {
      setTimeout(adjustOptionsWrapPosition, 0);
    }

    let selectedOptionImageSrc: string | null | undefined;

    if (!multiple && hasSelectedOptionImages) {
      const selectedOption = options.find(({ value: optionValue }) => value === optionValue);
console.log("FIND SELECTED OPTION", selectedOption);
      if (selectedOption) {
        selectedOptionImageSrc = selectedOption.selectedImageSrc;
      }
    }

    updateSettings((settings) => ({
      ...settings,
      focusedOption: multiple ? option.parentNode as HTMLDivElement : null,
      selectedOptionImageSrc
    }));

    updateSelectedValues(updatedSelections);
  }

  const onScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop } = event.currentTarget;
    const { optionsWrapperClassList } = settings;

    if (scrollTop > 0 && optionsWrapperClassList.includes("-scrolled")) {
      return;
    }

    showSearchInputWrapShadow(scrollTop > 0);
  }

  const onOptionMouseDown = () => {
    console.log('onOptionMouseDown');
    updateSettings((settings) => ({
      ...settings,
      isNavigatingWithKeyboard: false
    }));
  }

  const onOptionKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    console.log('onOptionKeyDown');
    preventScrollOnArrowPress(event);
    arrowNavigateOptions(event);
  }

  const onOptionsWrapperMouseOver = () => {
    if (!IS_OPENED) {
      console.log('adjustOptionsWrapPosition - onOptionsWrapperMouseOver');
      adjustOptionsWrapPosition();
    }
  }

  const onOptionFocus = (event: React.FocusEvent<HTMLButtonElement>) => {
    if (focusedOption !== event.currentTarget.parentNode) {
      const newFocusedOption = event.currentTarget.parentNode as HTMLDivElement;
  
      updateSettings((settings) => ({
        ...settings,
        focusedOption: newFocusedOption
      }));
    }
  };

  const visibleOptions = getVisibleOptions();

  const scrollableClasses = ["cs-select-scrollable"];

  if (isSearching && searchMatchCount === 0) {
    scrollableClasses.push("-no-results");
  }

  return (
    <dialog
      ref={optionsWrapRef}
      className={optionsWrapperClassList.join(" ")}
      style={optionsWrapperStyle}
      onKeyDown={(event) => preventScrollOnArrowPress(event)}
      onKeyUp={(event) => preventScrollOnArrowPress(event)}
      onMouseOver={() => onOptionsWrapperMouseOver()}
      open={IS_OPENED}
    >
      {options.length && searchable && <SearchInput/>}
      
      <div className="cs-select-options">
        <div
          ref={optionsScrollRef}
          className="cs-select-scrollable"
          onScroll={(event) => onScroll(event)}
        >
          {visibleOptions.map((data, index) => {
            const { value, html, searchMatchHighlightedHtml } = data;
            const classes = ["cs-select-option"];
            
            if (selected.includes(value)) {
              classes.push("-selected");
            }
            
            return (
              <div className={classes.join(" ")} key={`option-${index}`}>
                <button
                  type="button"
                  value={value}
                  tabIndex={IS_OPENED ? 0 : -1}
                  dangerouslySetInnerHTML={{ __html: isSearching ? searchMatchHighlightedHtml || "" : html as string }}
                  onClick={toggleSelectOption}
                  onKeyDown={onOptionKeyDown}
                  onKeyUp={preventScrollOnArrowPress}
                  onMouseDown={onOptionMouseDown}
                  onFocus={onOptionFocus}
                />
                {multiple && (
                  <span className="cs-select-option-checkbox">
                    <IconCheck/>
                  </span>
                )}
              </div>
            );
          })}

          {!options.length && (
            <div
              className="cs-select-no-options"
              dangerouslySetInnerHTML={{ __html: noOptionsText }}
            />
          )}

          {options.length && isSearching && searchMatchCount === 0 && (
            <div
              className="cs-select-no-results"
              dangerouslySetInnerHTML={{ __html: noResultsText }}
            />
          )}
        </div>
      </div>
    </dialog>
  );
}