import { createContext } from 'react';
import { DefaultSelectPropsType, SelectSettingsType, SelectContextType } from '../components/CustomizableSelect/types';
import { OpenState, HorizontalAlign, VerticalPosition, EventListenerKeys } from '../constants';

export const defaultSelectProps: DefaultSelectPropsType = {
  options: [],
  selected: [],
  wrapperAttrs: {},
  searchInputAttrs: { placeholder: "Search" },
  selectAttrs: {},
  selectElement: null,
  multiple: false,
  placeholder: "Select an option",
  clearable: false,
  showSelected: true,
  showArrow: true,
  searchable: false,
  noOptionsText: "No options found",
  noResultsText: "No results found",
  sortOptions: false,
  selectionDisplayType: "tag-summary",
  icon: null,
  useCustomIcons: false,
  optionsWrapPreferredWidth: 100,
  optionsWrapMaxHeight: 350
};

export const defaultSelectSettings: SelectSettingsType = {
  ...defaultSelectProps,
  init: false,
  uniqueHash: "",
  wrapperElement: null,
  selectHandleElement: null,
  optionsWrapperElement: null,
  optionsWrapperClassList: ["cs-select-options-wrap"],
  optionsWrapperStyle: {},
  optionsScrollElement: null,
  usesExistingSelectElement: false,
  openState: OpenState.CLOSED,
  isEmpty: true,
  focusedOption: null,
  optionTabIndex: 0,
  searchInputWrapElement: null,
  isSearching: false,
  searchInputValue: "",
  searchMatchCount: 0,
  isNavigatingWithKeyboard: false,
  hasSelectedOptionImages: false,
  selectedOptionImageSrc: null,
  dropdownRepositionData: {},
  updatedFromSelectChange: false,
  preventOnChangeEvent: false,
  optionsWrapPosition: {
    vertical: VerticalPosition.TOP,
    horizontal: HorizontalAlign.LEFT,
    lastCenterOffsetTop: 0
  },
  events: { ...EventListenerKeys }
};

const defaultContextValue: SelectContextType = {
  settings: defaultSelectSettings,
  updateSettings: () => {},
  showOptionsWrap: () => {},
  hideOptionsWrap: () => {},
  toggleOptionsWrap: () => {},
  arrowNavigateOptions: () => {},
  getVisibleOptions: () => [],
  getSelectedOptions: () => [],
  adjustOptionsWrapPosition: () => {},
  resetSearch: () => {},
  showSearchInputWrapShadow: () => {},
  preventScrollOnArrowPress: () => {},
  removeSelectedOption: () => {},
  updateSelectedValues: () => {},
  IS_OPENED: false,
  IS_CLOSING: false,
  IS_CLOSED: true
};

export const SelectSettingsContext = createContext<SelectContextType>(defaultContextValue);
