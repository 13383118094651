import React, { useContext } from "react";
import { SelectSettingsContext } from "../../contexts/SelectSettingsContext";
import IconXMark from "../icons/IconXMark";

export default function SelectedOptions(): React.JSX.Element {
  const {
    settings,
    removeSelectedOption
  } = useContext(SelectSettingsContext);
  
  const {
    options,
    selected,
    multiple,
    selectionDisplayType
  } = settings;
  
  const selectedOptions = options
    .filter(({ label, value }) => selected.includes(value) && label.length > 0);

  const classes = ["cs-select-selected-options"];

  if (multiple && selectionDisplayType === "tag-summary") {
    classes.push("-tag-summary");
  }
  
  return (
    <div className={classes.join(" ")}>
      {selectedOptions.map(({ label, value }, index) => (
        <div key={`selected-${index}`} className="cs-select-selected-option" data-value={value}>
          <div className="cs-select-selected-option-text" title={label} dangerouslySetInnerHTML={{ __html: label }}/>
          {multiple && selectionDisplayType === "removable-tags" && (
            <button
              type="button"
              className="cs-select-clear"
              onClick={removeSelectedOption}
              onKeyDown={removeSelectedOption}
              value={value}
              tabIndex={0}
            >
              <IconXMark/>
            </button>
          )}
        </div>
      ))}
      {multiple && selectionDisplayType === "tag-summary" && selectedOptions.length && (
        <span className="cs-select-selected-options-count">
          <span>{selectedOptions.length}</span>
        </span>
      )}
    </div>
  );
}