export const OpenState = {
  OPENED: "OPENED",
  CLOSING: "CLOSING",
  CLOSED: "CLOSED"
} as const;

export const VerticalPosition = {
  TOP: "-position-top",
  BOTTOM: "-position-bottom",
  CENTER: "-position-center"
} as const;

export const HorizontalAlign = {
  LEFT: "-align-left",
  RIGHT: "-align-right"
} as const;

export const EventListenerKeys = {
  changeCsSelect: "change.csSelect",
  clickShowSelectOptions: "click.showSelectOptions",
  csOptionsWrapReposition: "csOptionsWrapReposition",
  mousedownOutsideOptionsWrap: "mousedown.outsideOptionsWrap",
  resizeOptionsWrap: "resize.optionsWrap",
  wheelHideOptionsWrap: "wheel.hideOptionsWrap",
  scrollAdjustOptionsWrapPosition: "scroll.adjustOptionsWrapPosition"
};