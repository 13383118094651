import React, { useContext, useEffect, useRef } from "react";
import { SelectSettingsContext } from "../../contexts/SelectSettingsContext";
import IconMagnifyingGlass from "../icons/IconMagnifyingGlass";
import IconXMark from "../icons/IconXMark";

const REGEX_SAFE_WORD_REGEX = /(\.|\^|\$|\*|\+|\?|\(|\)|\[|\{|\\|\|)/g;
const tempDiv = document.createElement("div");

export default function SearchInput(): React.JSX.Element {
  const {
    settings,
    updateSettings,
    hideOptionsWrap,
    resetSearch,
    showSearchInputWrapShadow,
    adjustOptionsWrapPosition,
    IS_OPENED
  } = useContext(SelectSettingsContext);

  const {
    options,
    searchInputAttrs,
    isSearching,
    searchInputValue
  } = settings;

  const searchInputWrapRef = useRef(null);
  const searchInputRef = useRef(null);

  useEffect(() => {
    searchInputAttrs.className = `${(searchInputAttrs.className || "")} cs-select-search-input`.trim();
    
    updateSettings((settings) => ({
      ...settings,
      searchInputWrapElement: searchInputWrapRef?.current
    }));
  }, []);
  
  const search = (event: React.FormEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    const keywords = value.trim();

    if (keywords.length) {
      updateSettings((settings) => ({
        ...settings,
        searchInputValue: value
      }));
    }
    else {
      resetSearch();
      return;
    }

    const keywordListCandidates = keywords.replace(/\s{2,}/g, " ").split(" ");
    const keywordList: string[] = [];
    const keywordListRegexSafe: string[] = [];

    for (const keyword of keywordListCandidates) {
      if (!keywordList.includes(keyword)) {
        keywordList.push(keyword);
        
        keywordListRegexSafe.push(
          // regex safe keyword
          keyword.replace(REGEX_SAFE_WORD_REGEX, '\\$1')
        );
      }
    }

    const combinedKeywordListRegexSafe = keywordListRegexSafe.join('|');
    const combinedKeywordRegex = new RegExp(`(${combinedKeywordListRegexSafe})`, 'gi');

    const adjustedOptions = [...options];
    let matches: number[] = [];
    let priorityMatches: number[] = [];

    adjustedOptions
      .forEach((data, index) => {
        const { html, searchableText } = data;

        if (searchableText) {
          let isMatch = true;

          for (const keyword of keywordList) {
            if (searchableText.toLowerCase().indexOf(keyword.toLowerCase()) === -1) {
              isMatch = false;
              break;
            }
          }

          if (isMatch) {
            const keywordsRegexSafe = keywords.replace(REGEX_SAFE_WORD_REGEX, '\\$1');
            const priorityMatchKeywordsRegex = new RegExp(`^${keywordsRegexSafe}`, 'i');
            const searchMatchHighlightedHtml = highlightKeywordsInHtml(html as string, combinedKeywordRegex);

            adjustedOptions[index] = {
              ...adjustedOptions[index],
              isSearchMatch: true,
              searchMatchHighlightedHtml
            };

            if (priorityMatchKeywordsRegex.test(searchableText)) {
              priorityMatches.push(index);
            }
            else {
              matches.push(index);
            }
          }
          else {
            delete adjustedOptions[index].isSearchMatch;
            delete adjustedOptions[index].searchMatchOrder;
            delete adjustedOptions[index].searchMatchHighlightedHtml;
          }
        }
      });
    
    priorityMatches = orderMatchesByWordCountAsc(priorityMatches);
    matches = [...priorityMatches, ...matches];

    matches.forEach((optionIndex, index) => {
      adjustedOptions[optionIndex].searchMatchOrder = index;
    });

    if (!matches.length) {
      showSearchInputWrapShadow(false);
    }

    updateSettings((settings) => ({
      ...settings,
      options: adjustedOptions,
      isSearching: true,
      searchMatchCount: matches.length
    }));
  }

  const highlightKeywordsInHtml = (html: string, combinedKeywordRegex: RegExp) => {
    tempDiv.innerHTML = html;
    
    const searchableElement = tempDiv.querySelector(".-searchable") || tempDiv;
    let searchableHtml = searchableElement.innerHTML;
    const htmlTags = searchableHtml.match(/<\/?[^<>]+>/g);
    const htmlParts = [];
    
    if (htmlTags) {
      htmlTags.forEach((tag) => {
        const tagIndex = searchableHtml.indexOf(tag);
        let htmlBeforeTag = searchableHtml.substring(0, tagIndex);

        if (htmlBeforeTag.length) {
          if (htmlBeforeTag.trim().length) {
            htmlBeforeTag = htmlBeforeTag
              .replace(combinedKeywordRegex, '<strong class="-highlight">$1</strong>')
              .replace(/(\/strong>)(\s+)(<strong)/g, "$1<span>$2</span>$3");
          }

          htmlParts.push(htmlBeforeTag);
        }

        htmlParts.push(tag);
        searchableHtml = searchableHtml.substring(tagIndex + tag.length);
      });
    }

    if (searchableHtml.length) {
      if (searchableHtml.trim().length) {
        searchableHtml = searchableHtml
          .replace(combinedKeywordRegex, '<strong class="-highlight">$1</strong>')
          .replace(/(\/strong>)(\s+)(<strong)/g, "$1<span>$2</span>$3");
      }

      htmlParts.push(searchableHtml);
    }
    
    searchableElement.innerHTML = htmlParts.join(' ');

    return tempDiv.innerHTML;
  }

  const orderMatchesByWordCountAsc = (matches: number[]) => {
    const matchesByWordCount: { [wordCount: number]: number[] } = {};

    matches.forEach((optionIndex) => {
      const { searchableText } = options[optionIndex];
      const wordCount = (searchableText || "")?.trim().replace(/\s+/g, ' ').split(' ').length;

      if (!matchesByWordCount[wordCount]) {
        matchesByWordCount[wordCount] = [];
      }

      matchesByWordCount[wordCount].push(optionIndex);
    });

    const sortedMatchesByWordCount = Object.entries(matchesByWordCount)
      .sort(([wordCountA], [wordCountB]) => (
        wordCountA === wordCountB ? 0 : wordCountA > wordCountB ? 1 : -1
      ));

    const orderedMatches = sortedMatchesByWordCount
      .reduce((ordered: number[], [, matches]) => {
        ordered = [...ordered, ...matches];
        return ordered;
      }, []);

    return orderedMatches;
  };

  const escapeHideOptionsWrap = (event: React.KeyboardEvent<HTMLInputElement | HTMLButtonElement>) => {
    if (event.key === "Escape") {
      hideOptionsWrap();
    }
  };

  const onSearchClear = () => {
    resetSearch();

    if (searchInputRef?.current) {
      (searchInputRef.current as HTMLInputElement).focus();
    }
  };

  useEffect(() => {
    if (!searchInputValue.length) {
      adjustOptionsWrapPosition();
    }
  }, [searchInputValue]);

  return (
    <div
      ref={searchInputWrapRef}
      className={`cs-select-search-input-wrap ${isSearching ? "-not-empty" : ""}`}
    >
      <input
        type="text"
        spellCheck={false}
        {...searchInputAttrs}
        ref={searchInputRef}
        value={searchInputValue}
        onInput={(event) => search(event)}
        onKeyDown={escapeHideOptionsWrap}
        tabIndex={IS_OPENED ? 0 : -1}
      />
      <button
        type="button"
        className="cs-select-search-clear cs-select-clear"
        onClick={onSearchClear}
        onKeyDown={escapeHideOptionsWrap}
        tabIndex={IS_OPENED && isSearching ? 0 : -1}
      >
        <IconXMark/>
      </button>
      <div className="cs-select-search-glass">
        <IconMagnifyingGlass/>
      </div>
    </div>
  );
}